*
{
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}
.fa-heart
{
  color: #cc0000;
}
.checkout-logo{
  font-size: 24px;
  color: #4c4c4c;
  text-decoration: none;
  margin-left: 10%;
}
.checkout-logo img{
  width: 10%;
  margin-left: -0%;
  margin-right: 12px;
}
.checkout-header
{
  display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      min-height: 90px;
}
.watchlist
{
margin-right: 10%;
font-size: 25px;
}
.checkoutcontainer
{
   box-sizing: border-box;
    display: flex;
    flex-flow: nowrap;
    margin-top: -32px;
    width: calc(100% + 32px);
    margin-left: -32px;
}
.checkout-page
{
 
  background: #fff;
  margin-bottom: 20px;
  padding: 50px 10px 10px 29px;
  border-radius: 10px;
  width: 100%;
  gap: 5%;
  display: flex;
  flex-direction: column;
  margin-top: 6%;
}
.product-item-details
{
 
  display: flex;
  margin-left: 10%;
 margin-top: -6%;
 
}
.product-item-details img
{
width: 100px;
border-radius: 10px;
height: 150px;
}
.product-item-details h3{
  font-size: 15px;
  margin-left: 10px;
}
.minidetails
{
  display: flex;
  flex-direction: column;

}
.minidetails p{
  font-size: 13px;
  margin-left: 10px;
  display: flex;
}
.quantity-controls
{
  display: flex;
  margin-top: 15%;
  margin-bottom: 5%;
}
.quantity-controls button
{
  padding: 4px;
  font-size: 10px;
  margin-bottom: 55px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5%;
}
.remove-buttonn
{
  margin-bottom: 28%;
  padding: 0%;
  background-color: none;
}
.summarycontainor
{
  margin-right: 10px;
  background: #fff;
  margin-bottom: 20px;
  padding: 50px 10px 10px 29px;
  border-radius: 10px;
  width: 28%;
  margin-top: 5%;
margin-left: -15%;
margin-right: 5%;
  border-radius: 10px;
    background-color: #dbf5f7;
    padding: 23px 16px 16px;
   height: 10%;
}
.order-summary-container p,h3
{
 display: flex;
 justify-content: space-between;
 font-weight: 600;
 font-size: 15px;
}
.order-summary-container{
  line-height: 300%;
}
.order-summary-container h3{
  font-size: 20px;
}
.order-summary-container span
{
  margin-left: 28%;
  font-size: 10px;
}
.proceed-button
{
  
  font-weight: 600;
  color: #fff;
  letter-spacing: 2.33px;
  border: none;
  margin-left: 5%;
  padding: 17px 80px 16px;
  border-radius: 35px;
  box-shadow: 0 6px 10px 0 #00000017;
  background-image: linear-gradient(90deg, #00a1a5 -1%, #00c2c8 101%);
  cursor: pointer;
  margin-top: 10%;
}



/* ------------------------------------------------------------------------------ */

body {
  font-family: Arial, sans-serif;
}
.progress-bar {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  background-color: #e9e9e9;
}

.progress-bar .step {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.progress-bar .step.active {
  background-color: #4caf50;
  color: white;
}

.content {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 80%;
  margin-left: 10%;
}
.shipping-section
{
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
  margin-top: 10px;
}
.shipping-form {
  flex: 2;
  margin-right: 20px;
}

.shipping-form input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
}
.btn {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #4caf50;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
}

.btn:hover {
  background-color: #45a049;
}

.btn-secondary {
  padding: 10px 20px;
  font-size: 16px;
  color: #333;
  background-color: #ddd;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-secondary:hover {
  background-color: #ccc;
}

.shipping-section {
  max-width: 600px;
  margin: 0 auto;
}

.address-card {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 45%;
}

.address-card.selected {
  border-color: #00c2c8;
  background-color: #e7f3ff;
}

.select-address-button {
  background-color: #00c2c8;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.select-address-button:hover {
  background-color:#00c2c8;
}

.add-new-address-buttonn {
  display: block;
  margin: 10px 0;
  padding: 10px 15px;
  border: 1px dashed #00c2c8;
  background-color: #f9f9f9;
  cursor: pointer;
}
.add-new-address-buttonn:hover{
  background-color: #00a1a5;
}

.new-address-form input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn, .continue-buttonn {
  background-color: #00c2c8;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.btn:hover, .continue-buttonn:hover {
  background-color: #00a1a5;
}
.remove-address-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 10px;
}

.remove-address-button:hover {
  background-color: #cc0000;
}/* 
.payment-section {
  padding: 20px;
}

.payment-methods {
  margin-bottom: 20px;
}

.payment-methods label {
  display: block;
  margin-bottom: 10px;
}

.credit-card-form {
  margin-top: 20px;
}

.credit-card-form input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
}

.error-message {
  color: red;
  font-size: 14px;
}

.continue-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
}

.continue-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
} */

.buttonn {
  padding: 15px;
  margin-left: 20%;
  margin-top: 10px;
  background-color: #00c2c8;
  border: none;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 600;
}
.buttonn:hover
{
  background-color: #00a1a5;
  color: white;
  scale: 1.1;
}



/* 
--------------------------------------------------------------------------------------------- */



.payment-section {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.payment-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.payment-methods {
  flex: 1;
}

.payment-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.payment-option input {
  margin-right: 10px;
}

.card-details {
  flex: 1;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.card-details h3 {
  margin-bottom: 10px;
}

.card-details input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error-message {
  color: red;
  font-size: 0.9rem;
}

.payment-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.continue-button,
.back-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background: #333;
  color: #fff;
  cursor: pointer;
}

.continue-button:disabled {
  background: #bbb;
}

.continue-button:hover,
.back-button:hover {
  background: #555;
}
.payment-buttons-div
{
  display: flex;
}

/* 
------------------------------------------------------------------------------------- */

/* Media Query for Mobile Screens (max-width: 768px) */
@media only screen and (max-width: 768px) {
  body {
    font-size: 14px;
  }

  .checkout-logo {
    font-size: 18px;
    margin-left: 5%;
    text-align: center;
    margin-top: 10px;
  }

  .checkout-logo img {
    width: 8%;
    margin-left: 0;
    margin-right: 8px;
    
  }

  .checkout-header {
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
  }

  .watchlist {
    margin-right: 0;
    font-size: 18px;
    margin-top: 10px;
  }

  .checkoutcontainer {
    flex-direction: column;
    margin: 0;
    width: 100%;
    margin-left: 10px;
  }

  .checkout-page {
    width: 132%;
    margin: 10px -12px;
    padding: 20px;
  }
  .summarycontainor
  {
    margin-left: 10px;
    width: 114%;
    margin: 10px 0;
    padding: 20px;
  }

  .product-item-details {
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
    font-size: 10px;
    line-height: 12px;
    margin-top: 10px;
  }

  .product-item-details img {
    width: 80px;
    height: 100px;
    margin-bottom: 10px;
    margin-top: -10px;
  }

  .product-item-details h3 {
    font-size: 10px;
    text-align: center;
  }
.minidetails
{
  
  margin-top: -20px;
}
  .minidetails p {
    font-size: 8px;
    text-align: center;
    margin: 5px 0;
    margin-left: 10px;
  }
.remove-buttonn
{
  margin-top: -25px;
}
  .quantity-controls {
    margin-top: 20px;
    margin-bottom: 10px;
    justify-content: center;
  }

  .quantity-controls button {
    padding: 5px;
    font-size: 12px;
  }

  .summarycontainor {
    background-color: #dbf5f7;
    padding: 20px;
  }

  .proceed-button {
    padding: 12px 40px;
    margin-top: 20px;
    font-size: 14px;
    margin-left: 70px;
  }

  .progress-bar {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .progress-bar .step {
    margin-bottom: 10px;
  }

  .shipping-section {
    flex-direction: column;
    width: 100%;
    padding: 0;
  }

  .address-card {
    width: 100%;
    margin-bottom: 15px;
  }

  .new-address-form input,
  .shipping-form input {
    width: 100%;
    font-size: 14px;
  }

  .buttonn {
    margin-left: 0;
    width: 100%;
    padding: 10px;
    font-size: 14px;
    text-align: center;
  }

  .payment-container {
    flex-direction: column;
    gap: 10px;
  }

  .payment-section {
    padding: 15px;
  }

  .payment-buttons {
    flex-direction: column;
  }

  .continue-button,
  .back-button {
    width: 100%;
    margin-bottom: 10px;
  }
  .content
  {
    flex-direction: column;
  }
}
