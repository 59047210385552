.slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 450px; /* Fixed height, adjust as needed */
  margin-top: 6%;
}

.slider-wrapper {
  display: flex;
  width: 100%;
}

.slider-wrapper img {
  width: 100%;
  height: 100%; /* Ensure image fills the container height */
  object-fit: cover; /* Ensures the image covers the container without distortion */
}

.slider-btn {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.left-btn {
  left: 20px;
  z-index: 10;
}

.right-btn {
  right: 10px;
}

.slider-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 2;
}

.indicator {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
}

.indicator.active {
  background-color: white;
}
@media (max-width: 750px) {
  .slider-wrapper img {
    height: 45rem;
  }
  .slider-container {
    height: 45rem; /* Fixed height, adjust as needed */
    margin-top: 6%;
  }

}