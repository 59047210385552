body {
    font-family: Arial, sans-serif;
    background-color: #f7f7f7;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
}
header{
top: 0;
}
.camera-container{
    text-align: center;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 6%;
}

video {
    width: 50%;
    max-width: 400px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 15px;
    display: none; /* Initially hidden */
}

.mirrorbtn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
.camera-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
}

.mirrorbtn {
    padding: 10px 15px;
    margin-top: 10px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
}

.mirrorbtn:hover {
    background-color: #0056b3;
}
