.footer {
    background: #3C4658;
    color: white;
    padding: 20px 0;
    overflow: hidden;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    animation: slideIn 2s ease-in-out;
  }
  
  .footer-card {
    flex: 1;
    min-width: 240px;
    padding: 1rem;
    border-radius: 8px;
    color: #333;
    margin-left: 10px;
    margin-right: 30px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  }
  .footer-card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  }  
  /* Specific Card Backgrounds */
  .card-company {
    background-color: #daf3f5;
    display: flex;
    gap: 10%; 
    /* Light Blue */
  }
  
  .card-social {
    background-color:#daf3f5;
    text-align: center;
     /* Light Pink */
  }
  .social ul{
    display: flex;
    flex-wrap: wrap;
    gap: 10%;
    margin: 10%;
    text-align: center;
   
  }
 
  
.card-social ul li {
    animation: float 4s ease-in-out infinite;
  }
  
  .card-mail-office {
    background-color: #daf3f5 /* Light Green */
  }
  
  h4 {
    margin-bottom: 1rem;
    font-size: 1.2rem;
    color: #000;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  ul li {
    margin: 0.5rem 0;
  }
  
  ul li a {
    text-decoration: none;
    color: #007bff;
  }
  
  ul li a:hover {
    text-decoration: none;
  }
  
  address {
    font-style: normal;
    line-height: 1.5;
    color: #555;
  }
  
  .footer-bottom {
    margin-top: 2rem;
    text-align: center;
    font-size: 0.9rem;
    color: #f5f3f3;
  }
  .social img{
    width: 30px;
  }