/* Resetting margins and paddings */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
    background-color: #f2f2f2;
    color: #333;
    scroll-behavior: smooth; /* Adding smooth scroll behavior */
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Hero Section */
.hero {
    background-image: url('https://myraymond.com/cdn/shop/files/CJSQ00638-B7-1.jpg?v=1716968474');
    background-size: cover;
    background-position: center;
    height: 80vh; /* Adjusting hero height to allow scrolling */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
}
.heroo{
    background-image: url('https://cdn.prod.website-files.com/637f32081b68888e6d1bdd50/655f0bd9d06be741b026fb45_Women%20in%20vibrant%20Andhra%20Sarees%20and%20men%20in%20traditional%20Dhoti%20and%20Kurta.jpg');
    background-size: cover;
    margin-top: 60px;
    background-position: center;
    height: 80vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
}
.Formal

{
    background-image: url('https://xsuit.com/cdn/shop/articles/dress_codes_compared.png?v=1703889113&width=1000');
    background-size: cover;
    background-position: center;
    margin-top: 60px;
    height: 80vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
}

.polo {
    background-image: url('https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/6/n/s-ussht1903h-u-s-polo-assn-original-imagxe6sh9ththcd.jpeg?q=70&crop=false');
    background-size: cover;
    background-position: center;
    height: 80vh;
     margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
}
.polotshirt{
    background-image: url('https://hummel.net.in/cdn/shop/files/3430000-7480.png?v=1708583372');
    background-size: cover;
    background-position: center;
    height: 80vh;
     margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
}
.hero-content {
    background: rgba(255, 255, 255, 0.85);
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    animation: fadeIn 1.5s ease;
}

.hero h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

.hero p {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 30px;
}
.polo,.polotshirt h1{
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}
.polo,.polotshirt p{
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 30px;
}
.heroo h1{
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}
.heroo p{
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 30px;
}
.Formal h1{
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}
.Formal p{
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 30px;
}
.shop-now {
    padding: 10px 20px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.shop-now:hover {
    background-color: #555;
}

/* Common section styles */
section {
    padding: 60px 20px;
    background-color: #333;
    text-align: center;
    color: #fff;
}

h2 {
    font-size: 30px;
    color: #fff;
    margin-bottom: 40px;
}

.clothes-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    overflow-x: auto; /* Allow horizontal scrolling if items overflow */
    gap: 1rem;
}

.clothes-item {
    width: 30%;
    background-color: #f8f8f8;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, background-color 0.3s;
    margin-bottom: 20px;
}

.clothes-item img {
    max-width: 100%;
    border-radius: 10px;
}

.clothes-item p {
    font-size: 1.2rem;
    margin-top: 20px;
    color: #333;
}

.clothes-item:hover {
    transform: translateY(-10px);
    background-color: #f0f0f0;
}

/* Animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
/* Mobile Devices (Max Width: 768px) */
@media (max-width: 768px) {
    .hero, .heroo, .Formal, .polo, .polotshirt {
        height: 50vh; /* Reduce the height for smaller screens */
        background-size: contain;
        width: 105vh; /* Adjust background image scaling */
        margin-top: 10%;
    }
    .hero h1, .heroo h1, .Formal h1, .polo h1, .polotshirt h1 {
        font-size: 1.8rem; /* Smaller font sizes for titles */
    }

    .hero p, .heroo p, .Formal p, .polo p, .polotshirt p {
        font-size: 1rem; /* Smaller font sizes for paragraphs */
    }

    .clothes-item {
        width: 45%; /* Full width for clothes items */
    }

    .clothes-container {
        display: flex;
    }

    section {
        padding: 40px 10px; /* Adjust padding for smaller screens */
    }
}

/* Tablet Devices (Max Width: 1024px) */

