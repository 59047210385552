.carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 80%;
    margin: 20px auto;
    
    /* overflow: hidden; */
  }
  .upperdiv
  {
    background-color:#3C4658;
    width: 90%;
     margin: 5% 5% 0 5%;
    position: relative;
    color: aliceblue;
  }
  .carousel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 100px;
    width: 100%;
    margin-top: 10%;
    margin-bottom: 10%;
    
  }
  
  .card {
    text-align: center;
    opacity: 0.5;
    transition: transform 0.5s ease, opacity 0.5s ease;
    border: 8px solid rgb(255, 255, 255);
    border-radius: 5px;
  }
  
  .card img {
    width: 100%;
    height: auto;

  }
  
  .card.center {
    transform: scale(1.7); /* Largest card */
    opacity: 1;
    z-index: 3;
    width: 200px;
  }
  
  .card.side {
    transform: scale(1.5); /* Smaller side cards */
    opacity: 1;
    z-index: 2;
    width: 150px;
  }
  
  .card.far-side {
    transform: scale(1.5); /* Far smaller side cards */
    opacity: 1;
    z-index: 1;
    width: 100px;
  }
  
  .card.hidden {
    transform: scale(0.8); /* Hidden cards */
    opacity: 0.4;
  }
  
  .carousel-btn {
    background-color: #3C4658;
    color: rgb(254, 252, 252);
    border: none;
    padding: 15px 20px;
    border-radius: 50%;
    margin: -3%;
    cursor: pointer;
    font-size: 1.5em;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
  }
  
  .carousel-btn:hover {
    background-color: #b2b5b9;
  }
  
  .left-btnn {
    left: -60px; /* Adjust as needed */
  }
  
  .right-btnn {
    right: -60px; /* Adjust as needed */
  }
  h1{
    text-align: center;
    padding-top: 10px;
  }
