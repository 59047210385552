.watchlist-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.watchlist-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9;
}

.watchlist-item-image {
  width: 10%;
  height: 100%;
  object-fit: cover;
  margin-right: 20px;
  border-radius: 5px;
}

.watchlist-item-details {
  flex-grow: 1;
}

.price-section {
  font-size: 14px;
  color: #333;
}

.discount-price {
  color: green;
  font-weight: bold;
}

.main-price {
  text-decoration: none;
  margin-left: 10px;
}

.discount {
  color: red;
  font-weight: bold;
  margin-left: 10px;
}

.remove-button {
  background-color: #ff4c4c;
  color: white; border: none;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.remove-button:hover {
  background-color: #ff2a2a;opacity: 0.9;
}

.add-to-carta {
  background-color: #4caf50;
  color: white; border: none;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.add-to-carta:hover {
  background-color: #45a049;opacity: 0.9;
}
/* 
-------------------------------------------------------------------------------------- */

/* Media Query for Mobile Screens (max-width: 768px) */
@media only screen and (max-width: 768px) {
  .watchlist-container {
    padding: 10px;
    margin-top: 30%;
    
  }
.watchlist-container h2
{
  text-align: center;
}
  .watchlist-item {
   
    align-items: flex-start;
    margin-bottom: 15px;
    padding: 15px;
    width: 192%;
    margin-top: 13%;
  }

  .watchlist-item-image {
    width: 35%;
    height: auto;
    margin-bottom: 10px;
  }

  .watchlist-item-details {
    width: 100%;
  }

  .price-section {
    font-size: 12px;
  }

  .discount-price {
    font-size: 14px;
  }

  .main-price {
    font-size: 12px;
    margin-left: 5px;
  }

  .discount {
    font-size: 12px;
    margin-left: 5px;
  }

  .remove-button,
  .add-to-carta {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    margin-right: 0;
    margin-bottom: 10px;
    text-align: center;
  }

  .remove-button:hover,
  .add-to-carta:hover {
    opacity: 0.9;
  }
}
