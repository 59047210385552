/* Login.css */
/* @keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}



.login-section {
  display: flex;
  width: 100%;
  margin: auto;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  overflow: hidden;
  backdrop-filter: blur(10px);
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background: linear-gradient(-45deg, #ff9a9e, #fad0c4, #fbc2eb, #a1c4fd);
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

.image-container {
  flex: 1;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.welcome-image {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  border-radius: 10px;
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
}

.form-container {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.company-logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

h2 {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.login-form {
  width: 100%;
  max-width: 400px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-size: 14px;
  color: #555;
  display: block;
  margin-bottom: 5px;
}

.inputt {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  transition: all 0.3s ease;
}

input:focus {
  outline: none;
  border-color: #007BFF;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
}

.forgot-password {
  font-size: 12px;
  text-align: right;
  margin-top: 5px;
}

.forgot-password a {
  color: #007BFF;
  text-decoration: none;
}

.forgot-password a:hover {
  text-decoration: underline;
}

.error-message {
  color: #FF4A4A;
  font-size: 12px;
  margin-top: 5px;
}

.login-button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  color: #fff;
  background: linear-gradient(90deg, #667eea, #764ba2);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s;
}

.login-button:hover {
  background: linear-gradient(90deg, #764ba2, #667eea);
  transform: scale(1.05);
}

.divider {
  border: none;
  height: 1px;
  background: #ddd;
  margin: 20px 0;
}

.social-login {
  text-align: center;
}

.social-login p {
  font-size: 14px;
  color: #555;
  margin-bottom: 10px;
}

.social-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.social-button {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s;
}

.social-button.google {
  background: #DB4437;
}

.social-button.facebook {
  background: #4267B2;
}

.social-button i {
  margin-right: 8px;
}

.social-button:hover {
  opacity: 0.9;
}

.form-options {
  text-align: center;
  margin-top: 20px;
}

.form-options a {
  color: #007BFF;
  text-decoration: none;
}

.form-options a:hover {
  text-decoration: underline;
} */



label{
  font-size: 20px;
}

.containerrr{
  height: 100%;
  width: 100%;
  margin: auto;
  margin-top: 2%;
}

.containerrr{
  
  justify-content: center;
  align-items: center;
}

.containerrr{
  background-color: #FFF;
  padding: 32px 48px;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
}

.containerrr > h1{
  margin-bottom: 20px;
}

.containerrr > form{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.containerrr div{
  display: flex;
  flex-direction: column;
}

.containerrr input{
  width: 100%;
  font-size: 20px;
  padding: 10px;
  border: none;
  outline: none;
  border-bottom: 1px solid black;
}

.containerrr input::placeholder{
  font-size: 12px;
  font-style: italic;
}

button{
  background-color: purple;
  border: none;
  font-size: 20px;
  color: white;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin: 10px 0;
}