/* Global Styles */

@import url('https://fonts.googleapis.com/css2?family=Playwrite+HR+Lijeva+Guides&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", serif;
  font-weight: 300;
  font-style: normal;
}
/* Hide scrollbar for WebKit browsers */
::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
* {
  scrollbar-width: none; /* Firefox scrollbar hide */
}

/* For Internet Explorer, Edge */
* {
  -ms-overflow-style: none; /* IE and Edge scrollbar hide */
}

body {
  line-height: 1.6;
  background-color: #f8f9fa;
}

.containerr{
  max-width: 100%;
  margin: 0 auto;
  padding: 5px 15px;
}

/* Header Section */
.header {
  background-color: #3C4658;
  padding: 5px 0;
  position: fixed;
  z-index: 12;
  width: 100%;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 0%;
}

.logo {
  font-size: 24px;
  color: #fff;
  text-decoration: none;
  display: flex;
  
}
.logo img{
  width: 10%;
  margin-left: -0%;
  margin-right: 12px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}
.nav-links li {
  position: relative;
  margin-right: 20px;
}

.nav-links a {
  text-decoration: none;
  padding: 10px 15px;
  display: block;
  color: #333;
}

/* Dropdown menu */

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;  /* Position below the parent */
  left: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  color: black;
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
}

.dropdown-menu li {
  margin: 0;
}
.dropdown-menu li a{
  color: #000;
}
.dropdown-menu a {
  padding: 10px 20px;
  white-space: nowrap;
  color: #070707;
}

.dropdown-menu a:hover {
  background-color: black;
}

/* Show the dropdown on hover */
.dropdown:hover .dropdown-menu {
  display: block;
}


/* Additional styling (optional) */
.dropdown-menu a {
  text-decoration: none;
  display: block;
  color: #333;
  padding: 10px 15px;
}

.dropdown-menu a:hover {
  background-color: #666363;
}
/* User Section */
.user-section {
  position: relative;
  margin-right: 50px;
}

.user-icon {
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  margin-top: 10px;
  /* margin-right: -200px; */
}

.user-icon i {
  font-size: 24px;
  margin-right: -200px;
}

.user-icon .username {
  font-size: 16px;

}

.user-dropdown {
  position: absolute;
  top: 35px;
margin-left: -30px;
  background-color: #fff;
  width: 140px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  display: none;
  z-index: 100;

}

.user-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.user-dropdown ul li {
  border-bottom: 1px solid #ddd;
}

.user-dropdown ul li a {
  color: #333;
  text-decoration: none;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  transition: background 0.3s ease;
}
.username i{
  font-size: 24px;
}

.user-dropdown ul li a i {
  margin-right: 10px;
}

.user-dropdown ul li:hover a {
  background-color: #f0f0f0;
}

.user-section:hover .user-dropdown {
  display: block;
}

.user-section:hover .user-icon {
  color: #f0f0f0;
}
.cartIcon
{  list-style-type: none;
  margin-right: 10px;
  text-decoration: none;
  
}
.usercart
{
  display: flex;
  margin-right: 0%;
  gap: 0%;
  text-decoration: none;
}

/* Featured Products Section */

/* Footer Section */
.footer {
  background-color: #3C4658;
  color: #fff;
  text-align: center;
  padding: 20px 0;
}

/* Image Slider Styles */
/*  */

.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px; 
  
  
}

.collections-section h1 {
  text-align: center;
  margin-bottom: 30px;
  color: #2f2f2f;
  font-size: 30px;
}

.collection-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.collection-item {
  position: relative;
  overflow: hidden;
  border: 1px solid #ddd;
  text-align: center;
  transition: all 0.3s ease;
}

.collection-item img {
  width: 100%;
  height: 100%;
  transition: transform 1s ease;
}

.collection-item:hover img {
  transform: scale(1.5);
  
}

.collection-item h2 {
  margin-bottom: 0px;
  z-index: 11;
  transition: color 0.3s ease; 
}

.collection-item h2:hover {
  color: white; 
}

.collections-section
{
  background-color: white;
}
.text-overlay h2 
{
  color: white;
  font-size: 20px;
}

.text-overlay {
  position: absolute;
  bottom: -6%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  padding: 5px 10px 5px 10px;
  font-size: 2px;
  background-color: #444;
}

/* Category Section */
.category-section {
  background-color: #3C4658;
  padding: 60px 20px;
  font-family: "Poppins", serif;
  font-weight: 100;
  font-style: normal;
}

.category-section-inner {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.category-section-header {
  margin-bottom: 40px;
}

.category-caption {
  color: #ffffff;
  letter-spacing: 2px;
  font-size: 14px;
  text-transform: uppercase;
}

.category-title {
  color: #ffffff;
  font-size: 36px;
  font-weight: bold;
}

.category-collections {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.collection-item {
  background-color: #f7f7f7;
  border-radius: 0px;
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease;
}

.collection-item:hover {
  transform: translateY(-10px);
}

.collection-media img {
  width: 100%;
  height: auto;
}

.collection-text p {
  padding: 10px 0;
  color: #333;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
}

.product-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0px;
  
}

.product {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: center;
  width: 23%;
 margin-top: 20px;
 margin-bottom: 20px;
 margin-left: 0px;
 margin-right: 0px;
  padding: 0px;
  transition: transform 0.3s;
  text-decoration: none;
}

.product:hover {
  transform: scale(1.05);
}

.product-image {
  width: 100%;
  height: 400px;
  border-radius: 10px;
}

.product-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 600;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  letter-spacing: 0.3px;
 
}
.sub-title
{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px 0px 6px 0px;
  color: rgba(0, 0, 0, 0.4);
  display: block;
  width: 100%;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-left: 30px;
  
}
.product a{
  text-decoration: none;
}

.price{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10%;
  margin-bottom: 5%;
}
.main-pricee {
  
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  display: flex !important;
  align-items: center;
  color: rgba(0, 0, 0, 0.8) !important;
  border: none;
  position: relative;
  padding: 0px;
  margin: 0px;
  bottom: 0px;
  text-decoration: none;
}
.d-price
{
  display: inline-block;
  font-weight: 400;
  text-decoration: line-through;
  font-size: 14px;
  color: rgba(169, 169, 169, 0.8);
  padding: 0 3px 0px 6px;
}
.s-discount
{
  
  vertical-align: top;
  display: inline-block;
  color: #04ce00;
  font-size: 13px;
}
.button-group
{
  margin-bottom: 5%;
  display: flex;
  justify-content: space-evenly;
}
.menu-icon
{
  display: none;
}
.add-to-cart-btn
{
  background-color: #444;
  color: #fff;
  border: none;
  padding: 5px 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.add-to-cart-btn:hover {
  background-color: red;
}
.text-overlay,.collection-text
{
  text-decoration: none;
}
/* Footer Container */
/* .footer {
  background-color: #333; 
  color: #ffffff; 
  padding: 30px 20px;
  font-family: 'Arial', sans-serif;
  border-top: 1px solid #ccc;
}

.footer-container {

  display: grid;
  grid-template-columns: repeat(2, 2fr);
  padding: 20px 0 40px 0;
  column-gap: 20px;
}

.sec1 , .sec2
{
  display: flex;
}

.footer-column {
  width: 100%;
}

.footer-column h4 {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 15px;
  font-weight: bold;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a {
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
}

.footer-column ul li a:hover {
  color: #ffffff;
}

.footer-column address {
  font-size: 14px;
  line-height: 1.6;
  color: #ffffff;
}

.footer-bottom {
  text-align: center;
  padding: 15px;
  background-color: #e0e0e0; 
  color: #333;
  border-top: 1px solid #ccc;
}

.footer-bottom p {
  margin: 0;
  font-size: 14px;
}
.mail
{
  border-left: 1px solid #454d5e;
  padding-left: 32px;
} */

@media  (max-width: 760px) {
  /* Header adjustments */
  .header {
    margin-top: 0%;
  }

  /* Nav Links: Hide by default, show when active */
  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 57px;
    left: 0;
    background-color: #333;
    width: 40%;
    z-index: 9;
    padding-bottom: 100px;
    padding-top: 10px;
    transition: left 0.3s ease;
}

.nav-links.active {
    display: flex;
}

.nav-links li {
    margin-bottom: 10px;
}

.nav-links li a {
    color: #fff;
    font-size: 18px;
    text-align: left; /* Align text to the left for a better menu layout */
    display: block;
}

.menu-icon {
    display: inline;
    font-size: 24px;
    color: white;
    cursor: pointer;
    margin-left: 20px; /* Align menu icon with the left of the screen */
}

/* Logo positioning */
.header-logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

  /* Image slider adjustments */
  /* .slider-container {
    height: 350px;
    width: 105vh;
    margin-top: 15%;
  }
.slider-wrapper{
  margin-left: 0;
  object-fit: cover;
}
  .slider-wrapper img{
    margin-top: 0%;
    object-fit: cover;
    width: 100%;
  } */
  /* Username icon size */
  .username i {
    font-size: 20px;
    margin-right: -155px;
  }
  .cart-icon
  {
    margin-right: 10px;
  }
  /* Collection grid adjustments */
  .collection-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two elements per row */
    gap: 30px; /* Space between grid items */
    width: 100vh; /* Ensure the grid spans the full width */
    padding: 10px; /* Add padding for better spacing on mobile */
    box-sizing: border-box;
}


  /* Header container adjustments */
  .header-container {
    width: 50%;
  }

  /* Collections section margin */
  .collections-section {
    margin-top: -15%;
  }

  /* Product section for better mobile view */
  .product-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0;
    gap: 0px;
    width: 105vh;
    height: auto;
  }

  /* Category section width */
  .category-section {
    width: 105vh;
  }

  /* Individual product styles */
  .product {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    text-align: center;
    width: 45%;
    height: auto;
    padding: 10px;
    transition: transform 0.3s;
  }

  .product:hover {
    transform: scale(1.05);
  }

  .product-image {
    width: 100%;
    height: 300px;
    border-radius: 10px;
    object-fit: cover; /* Ensure images fit within bounds */
  }

  .product-title {
    font-size: 18px;
    margin: 10px 0;
  }

  .sub-title {
    margin-left: 10%;
    margin-top: -5%;
  }

  /* Pricing text adjustments */
  .main-price {
    font-size: 10px;
    margin-left: 0;
  }

  .d-price {
    font-size: 7px;
  }

  .s-discount {
    font-size: 8px;
  }

  /* Button styling for smaller screens */
  .add-to-cart-btn {
    margin-bottom: 10%;
  }

  .button-group button {
    padding: 10px 8px;
    font-size: 10px;
  }

  /* Dropdown adjustments */
  .header-dropdown-menu {
    width: 100%;
    z-index: 12;
    color: #000;
    background-color: #000;
  }

  .carousel-container {
    width: 100%;
}
.upperdiv {
  width: 100vh;
  height: auto;
}
.carousel {
    gap: 20px; /* Adjust for smaller screens */
}

.card {
    width: 90%;
}

.card.center {
    transform: scale(1.4);
    width: 150px;
}

.card.side,
.card.far-side {
    transform: scale(1);
    width: 120px;
}

.carousel-btn {
    padding: 8px 12px;
    font-size: 30px;
}

.left-btnn {
    left: 10px;
    
}
.right-btnn
{right: 10px;

}

h1 {
    font-size: 1.5em;
    padding-top: 5px;
}
.footer {
  padding: 15px 10px;
  width: 105vh;
}

.footer-container {
  
  gap: 2rem;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.footer-card {
  margin: 0 auto;
  width: 45%;
  max-width: 90%;
  padding: 1rem;
  font-size: 10px;
}

.social ul {
  flex-direction: column;
}

h4 {
  font-size: 1rem;
}

.footer-bottom {
  font-size: 0.8rem;
  padding: 10px 0;
}

.social img {
  width: 25px;
}
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;  /* Position below the parent */
  left: 0;
  list-style: none;
  padding: 0;
  width: 104%;
  margin: 0;
  color: black;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
}

.dropdown-menu li {
  margin: 0;
}
.dropdown-menu li a{
  color: #000;
}
.dropdown-menu a {
  padding: 10px 20px;
  white-space: nowrap;
  color: #070707;
}

.dropdown-menu a:hover {
  background-color: black;
}

/* Show the dropdown on hover */
.dropdown:hover .dropdown-menu {
  display: block;
}

/* Additional styling (optional) */
.dropdown-menu a {
  text-decoration: none;
  display: block;
  padding: 10px 15px;
}

.dropdown-menu a:hover {
  background-color: #666363;
}
.container
{
  margin-top: 10%;
}
.product-section
{
  margin-top: 10%;
}
}

