.cart-count {
    background-color: red;
    color: rgb(255, 255, 255);
    border-radius: 50%;
    padding: 0.2em 0.5em;
    font-size: 10px;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 100;
  }
  .cart-icon 
  {
    color: white;
    margin-right: 20px;
    font-size: 20px;
    margin-top: 10px;
  }
  