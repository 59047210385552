/* Product Page Styling */
.product-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px;
    gap: 20px;
    margin-top: 8%;
  }
  
  .product-images {
    flex: 1;
    max-width: 500px;
  }
  
  .product-details {
    flex: 2;
    max-width: 600px;
  }
  
  .product-details h1 {
    font-size: 2.0rem;
    font-weight: bold;
    margin: 10px 0;
    color: #333;
    margin-left: -4%;
  }
  
  .sub-head {
    font-size: 1.5rem;
    font-weight: 600;
    color: #888;
    margin-left: 0%;
  }
  
  .price-sectionn {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 15px;
  }
  
  .discount-price {
    font-size: 1.6rem;
    color: #e74c3c;
    font-weight: bold;
  }
  
  .original-pricee {
    font-size: 1rem;
    text-decoration: line-through;
    color: #999;
  }
  
  .discount-pricee {
    font-size: 1.4rem;
    color: #27ae60;
    font-weight: 600;
  }
  
  .product-description {
    margin-top: 20px;
  }
  
  .product-description h3 {
    font-size: 1.6rem;
    font-weight: bold;
  }
  
  .product-description p {
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
  }
  
  .size-selection {
    margin-top: 10%;
  }
  
  .size-selection label {
    font-size: 1.1rem;
    font-weight: 500;
    color: #333;
  }
  
  .size-selection select {
    padding: 10px;
    font-size: 1rem;
    width: 150px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    color: #333;
  }
  
  .button-group {
    display: flex;
    gap: 20px;
    margin-top: 10%;
  }
  
  .cart-button {
    padding: 15px 40px;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    transition: all 0.3s ease;
  }
  
  .add-to-cart-btn {
    background-color: #3498db;
    color: white;
  }
  
  .add-to-cart-btn:hover {
    background-color: #2980b9;
  }
  
  .buy-now-btn {
    background-color: #27ae60;
    color: white;
  }
  
  .buy-now-btn:hover {
    background-color: #2ecc71;
  }
  
  /* Feature Box Styling */
  .charge-box-container {
    margin-top: 50px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }
  
  .charge-box {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 2%;
    margin-bottom: 2%;
  }
  
  .charge-box-item {
    flex: 1;
    text-align: center;
  }
  
  .charge-box-image {
    width: 70px;
    height: 70px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .charge-box-text {
    font-size: 1rem;
    color: #333;
    font-weight: 500;
  }
  
  /* Error Page Styling */
  .error-page {
    text-align: center;
    margin-top: 50px;
  }
  
  .error-page h1 {
    font-size: 2rem;
    color: #e74c3c;
  }
  
  .error-page button {
    padding: 10px 20px;
    font-size: 1.2rem;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .error-page button:hover {
    background-color: #2980b9;
  }
  /* Product Images Section */
.product-images {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .main-image {
    width: 90%;
    max-width: 500px;
    height: 80%;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  

  .small-images {
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-top: 10px;
  }
  
  .small-image {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .small-image:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
  }
  .similar-products-section {
    padding: 20px;
    background-color: #f5f5f5;
  }
  
  .similar-products-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .similar-product-card {
    background-color: #fff;
    padding: 15px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: calc(33.33% - 20px);
    box-sizing: border-box;
  }
  
  .similar-product-card img {
    max-width: 100%;
    border-radius: 5px;
  }
  
  .similar-product-card h3 {
    margin: 10px 0;
  }
  
  .similar-product-card span {
    font-size: 1.2em;
    color: #d9534f;
  }
  
  .view-details-btn {
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .view-details-btn:hover {
    background-color: #0056b3;
  }
  



  #magnifyResult {
    display: none;
    position: absolute;
    width: 120%;
    height: 100%;
    border: 1px solid #000;
    overflow: hidden;
    z-index: 1;
    pointer-events: none;
    margin-left: 230%;
  }
  .hover-overlay:hover {
    opacity: 1;
  }
  @media (max-width: 767px) {
    
    .product-images {
      width: 100%;
     
      margin-bottom: 20px;
    }
  
    .product-details {
      width: 100%;
      max-width: 100%;
    }
  
    .product-details h1 {
      font-size: 1.6rem;
    }
  
    .sub-head {
      font-size: 1.3rem;
      margin-left: 0;
    }
  
    .price-section {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 10px;
      font-size: 12px;
    }
  
    .discount-price {
      font-size: 1.4rem;
    }
  
    .original-price {
      font-size: 1.2rem;
    }
  
    .discount {
      font-size: 1.3rem;
    }
  
    .product-description h3 {
      font-size: 1.4rem;
    }
  
    .product-description p {
      font-size: 0.9rem;
    }
  
    .size-selection {
      margin-top: 10%;
    }
  
    .size-selection label,
    .size-selection select {
      font-size: 1rem;
    }
  
    .button-group {
      flex-direction: column;
      align-items: center;
      margin-top: 5%;
    }
  
    .cart-button {
      width: 100%;
      padding: 15px;
      font-size: 1rem;
    }
  
    .add-to-cart-btn,
    .buy-now-btn {
      width: 100%;
      text-align: center;
    }
  
    .charge-box-container {
      padding: 20px;
    }
  
    .charge-box {
      flex-direction: column;
      gap: 20px;
    }
  
    .charge-box-item {
      text-align: center;
    }
    .product-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      margin: 20px;
      
      margin-top: 15%;
    }
    /* Product Images - Move small images below main image */
    .product-images {
      display: flex;
      align-items: center;
      position: relative;
    }
  
    .main-image {
      width: 65vh;
  
    }
  
    .small-images {
      display: flex;
      flex-direction: row;
      gap: 15px;
      margin-top: 15px;
    }
  
    .small-image {
      width: 50px;
      height: 50px;
    }
  
    .similar-products-section {
      padding: 15px;
    }
  
    .similar-product-card {
      width: calc(50% - 10px); /* Adjust for smaller screens */
    }
    .sizebtn
    {
      margin-top: 20%;
      margin-left: -130%;
    }
    .charge-box
    {
      display: flex;
      flex-direction: row;
      
    }
  }