/* AboutSection.css */

@import url('https://fonts.googleapis.com/css2?family=Playwrite+HR+Lijeva+Guides&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.about-section {
    padding: 50px 20px;
    background: linear-gradient(135deg, #f7e3e3, #c4c4f2); /* Soft background gradient */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    overflow: hidden;
    opacity: 0;
    gap: 50px;
    margin-top: 30px;
    transform: translateY(20px);
    transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
    font-family: "Poppins", serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .about-section.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  
  .about-content {
    max-width: 900px;
    text-align: center;
    animation: slideUp 1s ease-out;
  }
  
  .about-title {
    font-size: 3rem;
    color: #333;
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .about-text {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.7;
    margin-bottom: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Arial', sans-serif;
    text-align: justify;
  }
  
  .about-text:last-child {
    margin-bottom: 40px;
  }
  
  .about-img-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    animation: fadeInUp 1s ease-out;
  }
  
  .about-img {
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .about-img:hover {
    transform: scale(1.05);
  }
  
  /* Animation for Image Fade-in */
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Animation for Text Slide-Up */
  @keyframes slideUp {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive Design */
  
@media (max-width: 768px) {
    .about-section {
        flex-direction: column;
        gap: 30px;
        padding: 40px 15px;
        width: 105vh;
    }

    .about-title {
        font-size: 2.5rem;
    }

    .about-text {
        font-size: 1rem;
    }

    .about-img {
        max-width: 50%;
        margin-top: 20px;
    }
}